import React from "react";

interface Props {
  size?: "medium" | "big";
  className?: string;
}

const Duimpje: React.FC<Props> = ({ className = "", size = "medium" }) => {
  const widths = {
    medium: 25,
    big: 35,
  };

  return (
    <img
      className={className}
      style={{ width: widths[size] }}
      src="/images/duimpje.png"
      alt="Duimpje"
    />
  );
};

export default Duimpje;
