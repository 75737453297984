import { OverigPakket, Pakket } from "./pakketInfo";

export const pakketten: Pakket[] = [
  {
    title: "1",
    perks: [
      "10 Rijlessen €57,- per les",
      "Praktijk examen AVB & AVD",
    ],
    price: 1050,
  },
  {
    title: "2",
    perks: [
      "15 Rijlessen €51,- per les",
      "Praktijk examen AVB & AVD",
    ],
    price: 1245,
  },
  {
    title: "3",
    perks: [
      "20 Rijlessen €49,- per les",
      "Praktijk examen AVB & AVD",
    ],
    price: 1460,
  },
  {
    title: "4",
    perks: [
      "25 Rijlessen €47,- per les",
      "Praktijk examen AVB & AVD",
    ],
    price: 1655,
  },
  {
    title: "5",
    perks: [
      "35 Rijlessen €45,- per les",
      "Praktijk examen AVB & AVD",
    ],
    price: 2055,
  },
];

export const overigePakketten: OverigPakket[] = [
  {
    title: "AVB examen",
    price: 190,
  },
  {
    title: "AVD examen",
    price: 290,
  },
  {
    title: "Proefles 90 minuten",
    price: 50,
  },
  {
    title: "Losse les",
    price: 55,
  },
];
