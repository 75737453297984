import React from "react";
import Box from "../general/Box";
import Flex from "../general/Flex";
import Duimpje from "../general/images/Duimpje";
import { H2, P } from "../general/Typography";
import { OverigPakket, Pakket } from "./pakketInfo";
import { Row, Col } from "reactstrap";

interface Props {
  pakketten: Pakket[];
  overigePakketten: OverigPakket[];
}

const PakkettenLijst: React.FC<Props> = ({ pakketten, overigePakketten }) => {
  return (
    <Row>
      {pakketten.map((pakket, i) => (
        <>
          <Col xs="12" md="5">
            <Box key={pakket.title} text-center mb="5">
              <H2 display>{pakket.title}</H2>
              {pakket.perks.map(perk => (
                <Flex key={perk} align-items="center" my="3">
                  <Box mr="4">
                    <Duimpje />
                  </Box>
                  <P
                    className="text-left mb-0"
                    color="blue"
                    bold
                    fontSize="large"
                  >
                    {perk}
                  </P>
                </Flex>
              ))}
              <P fontSize="xxl" bold className="text-right pr-3">
                €{pakket.price}
              </P>
              {pakket.note && (
                <P light className="text-left">
                  * {pakket.note}
                </P>
              )}
            </Box>
          </Col>
          {i % 2 === 0 && <Col xs="0" md="2"></Col>}
        </>
      ))}

      <Col xs="12" md={{ size: 10, offset: 1 }} lg={{ size: 8, offset: 2 }}>
        <Box my="5" text-center>
          <P bold fontSize="large">
            Betaal je in één keer? Dan krijg je 1x gratis rijles kado!
          </P>
        </Box>

        <Box text-center text-md-left my="4" my-md="5">
          <H2>Overige</H2>
        </Box>

        {overigePakketten.map(pakket => (
          <Box d-md-flex align-items-md-center mb="5" mb-md="4">
            <Flex
              key={pakket.title}
              align-items="center"
              flex-grow="1"
              mb="3"
              mb-md="0"
            >
              <Box mr="4">
                <Duimpje />
              </Box>
              <P
                className="text-left mb-0 flex-grow-md-1"
                color="blue"
                bold
                fontSize="large"
              >
                {pakket.title}
              </P>
            </Flex>
            <P bold fontSize="large" className="mb-0">
              €{pakket.price}
            </P>
          </Box>
        ))}
      </Col>
    </Row>
  );
};

export default PakkettenLijst;
